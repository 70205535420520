import React, { useEffect } from "react";
import "./App.css";

function App() {
  useEffect(() => {
    const root = document.getElementById("root");
    const handleAnimationEnd = () => {
      root!.classList.add("expand-complete");
    };

    root!.addEventListener("animationend", handleAnimationEnd);

    return () => {
      root!.removeEventListener("animationend", handleAnimationEnd);
    };
  }, []);

  return (
    <>
      <h1>ULETAEM.COM</h1>
      <p className="title-p">Aggregator of tickets, hotels and tours</p>
      <h2>Final Task for <a className="linkRS" href="https://rs.school/">RS School</a>. Coming soon...</h2>   
    </>
  );
}

export default App;
